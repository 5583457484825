import {
  Box,
  inputLabelClasses,
  Modal,
  OutlinedInputProps,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import AddImageIcon from "assets/categories/AddImageIcon";
import CloseIcon from "assets/categories/CloseIcon";
import SwitchComp from "components/switchComp";
import { HttpRequest } from "plugins/httpRequest";
import { useAppDispatch, useAppSelector } from "services";
import {
  createCategory,
  updateCategories,
  deleteCategory,
  getCategories,
} from "services/categoriesSlice";
import { RequestType } from "plugins/httpRequest";
import { Category } from "interfaces/category";
import { IMG_URL } from "constants/index";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";

type CategoryModalProps = {
  open: boolean;
  onClose: any;
  categoryImg: string;
  setCategoryImg: any;
  setCropperModal: any;
  croppedImgSrc: any;
  category?: Category;
};

const CategoryModal: FC<CategoryModalProps> = ({
  open,
  onClose,
  categoryImg,
  setCategoryImg,
  setCropperModal,
  croppedImgSrc,
  category,
}) => {
  const user = useAppSelector((state) => state.auth.user);
  const { loading } = useAppSelector((state) => state.categories);
  console.log(loading);
  const [checked, setChecked] = useState(false);
  const imageRef = useRef();

  const [categoryName, setCategoryName] = useState(category?.title);
  const [categoryPoint, setCategoryPoint]: any = useState(
    category?.defaultCardPoint
  );
  useEffect(() => {
    setCategoryName(category?.title);
    setCategoryPoint(category?.defaultCardPoint);
    setChecked(category?.status === "ACTIVE");
  }, [category]);
  const dispatch = useAppDispatch();
  const { countryId } = useAppSelector((state) => state.countries);

  const handleFileUpload = (e: any) => {
    setCropperModal(true);
    const media = e.target.files[0];
    setCategoryImg(media);
  };

  useEffect(() => {
    setCategoryImg(null);
  }, [open, setCategoryImg]);

  const checkCategoryField = () => {
    if (!categoryImg && !category?.media) {
      toast.error("Please upload an image for category");
      return false;
    }
    if (!categoryName) {
      toast.error("Please enter a name for category");
      return false;
    }

    if (!croppedImgSrc.startsWith("data") && !category?.media) {
      toast.error("Please upload an image for category");
      return false;
    }
    return true;
  };

  const handleDeleteCategory = async (categoryId: string) => {
    dispatch(deleteCategory(categoryId))
      .then(() => {})
      .finally(() => {
        onClose();
        dispatch(getCategories({ page: 1, user }));
      });
  };

  const saveCategory = async () => {
    if (checkCategoryField()) {
      const formData = new FormData();
      formData.append("extension", "jpg");
      formData.append("content", croppedImgSrc);
      let imageRes;
      if (croppedImgSrc.startsWith("data")) {
        imageRes = await HttpRequest<any, { fileName: string }>({
          url: "Admin/Upload/Base64",
          method: RequestType.POST,
          body: formData,
          isFormData: true,
        });
      }

      if (category) {
        dispatch(
          updateCategories({
            category: {
              id: category.id,
              defaultCardPoint: Number(categoryPoint) | 0,
              media: imageRes ? imageRes?.fileName : category.media,
              title: categoryName!,
              countryId,
              status: checked ? "ACTIVE" : "PASSIVE",
              updatedById: "e48cba54-fa3d-4530-bf03-5dc308080c2f",
            },
          })
        )
          .then(() => {})
          .finally(() => {
            onClose();
            dispatch(getCategories({ page: 1, user }));
          });
      } else {
        dispatch(
          createCategory({
            category: {
              defaultCardPoint: Number(categoryPoint) | 0,
              media: imageRes?.fileName!,
              title: categoryName!,
              countryId,
              status: checked ? "ACTIVE" : "PASSIVE",
              updatedById: "e48cba54-fa3d-4530-bf03-5dc308080c2f",
            },
          })
        )
          .then(() => {})
          .finally(() => {
            onClose();
            dispatch(getCategories({ page: 1, user }));
          });
      }
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "591px",
          height: "320px",
          bgcolor: "background.paper",
          padding: "24px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          onClick={onClose}
          style={{
            position: "absolute",
            top: "0px",
            right: "0px",
            cursor: "pointer",
            padding: "12px 16px",
          }}
        >
          <CloseIcon />
        </Box>
        <Typography variant="paragraph" color="#000">
          Add Category
        </Typography>
        <Typography variant="paragraph" m="16px 0px">
          Please type a name and upload a for category.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
          }}
        >
          <input
            // @ts-ignore
            ref={imageRef}
            type="file"
            accept="image/*,.svg+xml"
            style={{ display: "none" }}
            onChange={handleFileUpload}
          />
          {!categoryImg && !category ? (
            <AddImageIcon
              onClick={() => {
                // @ts-ignore
                imageRef.current.click();
              }}
            />
          ) : (
            <Box
              sx={{
                width: "122px",
                height: "122px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              component="img"
              src={
                croppedImgSrc
                  ? croppedImgSrc || categoryImg
                  : IMG_URL + category?.media
              }
              onClick={() => {
                // @ts-ignore
                imageRef.current.click();
              }}
            />
          )}
          <Box
            sx={{
              flex: 1,
              flexDirection: "column",
              height: "155px",
              gap: "16px",
            }}
          >
            <TextField
              sx={{
                width: "100%",
                mb: "16px",
              }}
              id="categoryName"
              label="Category name"
              variant="filled"
              onChange={(e) => setCategoryName(e.target.value)}
              value={categoryName}
              InputProps={
                {
                  disableUnderline: true,
                } as Partial<OutlinedInputProps>
              }
              InputLabelProps={{
                sx: {
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#c2c2c2",
                  },
                },
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <TextField
                id="categoryPoints"
                label="Category points"
                variant="filled"
                onChange={(e) => {
                  const value = e.target.value;
                  setCategoryPoint(value === "" ? "" : Number(value));
                }}
                value={categoryPoint !== null ? categoryPoint : ""}
                onBlur={() => {
                  if (categoryPoint === "" || isNaN(Number(categoryPoint))) {
                    setCategoryPoint(0);
                  }
                }}
                InputProps={
                  {
                    disableUnderline: true,
                  } as Partial<OutlinedInputProps>
                }
                InputLabelProps={{
                  sx: {
                    [`&.${inputLabelClasses.shrink}`]: {
                      color: "#c2c2c2",
                    },
                  },
                }}
              />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <Typography variant="paragraph">
                  {checked ? "Active" : "Passive"}
                </Typography>
                <SwitchComp checked={checked} setChecked={setChecked} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" gap="8px" alignItems="center">
          <LoadingButton
            loading={loading}
            disabled={loading}
            variant="contained"
            onClick={saveCategory}
            sx={{ width: "100%", flex: 2 }}
          >
            <Typography variant="buttonText">
              {category ? "Update" : "Save"}
            </Typography>
          </LoadingButton>
          {category && (
            <LoadingButton
              loading={loading}
              disabled={loading}
              onClick={() => handleDeleteCategory(category.id)}
              variant="contained"
              sx={{
                width: "100%",
                backgroundColor: "#f44336",
                flex: 1,

                "&:hover": {
                  backgroundColor: "#f44336",
                },
              }}
            >
              <Typography variant="buttonText">Delete Category</Typography>
            </LoadingButton>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default CategoryModal;
