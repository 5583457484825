import React, { useEffect, useRef, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "services";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import useClickOutside from "utils/useClickOutside";
import { addOption } from "services/optionsSlice/api";
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";

type CardOptionsProps = {
  leftOption: any;
  rightOption: any;
  topOption: any;
  showTopOption: boolean;
  setData: any;
};

const CardOptions: React.FC<CardOptionsProps> = ({
  leftOption,
  rightOption,
  topOption,
  showTopOption,
  setData,
}) => {
  const dispatch = useAppDispatch();
  const { options } = useAppSelector((state) => state.options);
  const { languageId } = useAppSelector((state) => state.languages);
  const { t } = useTranslation(["cards"]);

  const leftOptions = options?.filter(
    (option: any) => option?.position === "LEFT"
  );

  const [leftText, setLeftText] = useState("");
  const [isLeftVisible, setLeftVisible] = useState(false);
  const [leftSelected, setLeftSelected] = useState() as any;

  const filteredLeftOptions = leftOptions
  ?.filter((item: any) =>
    item.name.toLowerCase().includes(leftText.toLowerCase())
  )
  .sort((a: any, b: any) => {
    const hasEmojiA = /[\p{Emoji}]/u.test(a.name);
    const hasEmojiB = /[\p{Emoji}]/u.test(b.name);

    if (hasEmojiA && !hasEmojiB) {
      return 1;
    } else if (!hasEmojiA && hasEmojiB) {
      return -1;
    }
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  });



  const outsideLeftRef = useRef() as any;
  useClickOutside(outsideLeftRef, () => setLeftVisible(false));

  useEffect(() => {
    setLeftSelected(leftOptions?.find((item: any) => item.id === leftOption));
  }, [leftOptions, leftOption]);

  const addLeftOption = () => {
    const option: any = {
      name: leftText,
      languageId: languageId,
      status: "ACTIVE",
      position: "LEFT",
    };
    
    if(leftText.length > 15){
      toast.error(t("left-length-error") as string);
      return;
    }else{
      dispatch(addOption({ option })).then((res: any) => {
        setData((prev: any) => {
          return { ...prev, leftOption: res?.payload?.id };
        });
        setLeftVisible(false);
      });
    }
  };

  const rightOptions = options?.filter(
    (option: any) => option?.position === "RIGHT"
  );

  const [rightText, setRightText] = useState("");
  const [isRightVisible, setRightVisible] = useState(false);
  const [rightSelected, setRightSelected] = useState() as any;

  const filteredRightOptions = rightOptions
  ?.filter((item: any) =>
    item.name.toLowerCase().includes(rightText.toLowerCase())
  )
  .sort((a: any, b: any) => {
    const hasEmojiA = /[\p{Emoji}]/u.test(a.name);
    const hasEmojiB = /[\p{Emoji}]/u.test(b.name);

    if (hasEmojiA && !hasEmojiB) {
      return 1;
    } else if (!hasEmojiA && hasEmojiB) {
      return -1;
    }
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  });

  const outsideRightRef = useRef() as any;
  useClickOutside(outsideRightRef, () => setRightVisible(false));

  useEffect(() => {
    setRightSelected(
      rightOptions?.find((item: any) => item.id === rightOption)
    );
  }, [rightOptions, rightOption]);

  const addRightOption = () => {
    const option: any = {
      name: rightText,
      languageId: languageId,
      status: "ACTIVE",
      position: "RIGHT",
    };

    if(rightText.length > 15){
      toast.error(t("right-length-error") as string);
      return;
    }else{
    dispatch(addOption({ option })).then((res: any) => {
      setData((prev: any) => {
        return { ...prev, rightOption: res?.payload?.id };
      });
      setRightVisible(false);
    });
  }
  };

  const topOptions = options?.filter(
    (option: any) => option?.position === "TOP"
  );

  const [topText, setTopText] = useState("");
  const [isTopVisible, setTopVisible] = useState(false);
  const [topSelected, setTopSelected] = useState() as any;

  const filteredTopOptions = topOptions
  ?.filter((item: any) =>
    item.name.toLowerCase().includes(topText.toLowerCase())
  )
  .sort((a: any, b: any) => {
    const hasEmojiA = /[\p{Emoji}]/u.test(a.name);
    const hasEmojiB = /[\p{Emoji}]/u.test(b.name);

    if (hasEmojiA && !hasEmojiB) {
      return 1;
    } else if (!hasEmojiA && hasEmojiB) {
      return -1;
    }
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  });


  const outsideTopRef = useRef() as any;
  useClickOutside(outsideTopRef, () => setTopVisible(false));

  useEffect(() => {
    setTopSelected(topOptions?.find((item: any) => item.id === topOption));
  }, [topOptions, topOption]);

  const addTopOption = () => {
    const option: any = {
      name: topText,
      languageId: languageId,
      status: "ACTIVE",
      position: "TOP",
    };
    if(topText.length > 15){
      toast.error(t("top-length-error") as string);
      return;
    }else{
    dispatch(addOption({ option })).then((res: any) => {
      setData((prev: any) => {
        return { ...prev, topOption: res?.payload?.id };
      });
      setTopVisible(false);
    });
  }
  };


  return (
    <Box>
      <Box sx={{marginBottom: '26px'}}>
        <Typography variant="paragraph" color="#FF9F27" mr="8px">
        {t('options')}:
        </Typography>
        <Typography variant="paragraph">
        {t('options-info')}
        </Typography>
      </Box>


      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
        }}
      >
        {/* LEFT */}
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
          }}
        >
          <Typography variant="paragraph" color="#C2C2C2" mb="8px">
          {t('left-svayp')}
          </Typography>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              display: "flex",
              gap: "5px",
            }}
            ref={outsideLeftRef}
          >
            <TextField
              autoComplete="off"
              sx={{ flex: 1 }}
              placeholder={t('select-option')}
              value={leftSelected ? leftSelected.name : leftText}
              onChange={(e) => {
                setLeftSelected();
                setData((prev: any) => {
                  return { ...prev, leftOption: "" };
                });
                setLeftText(e.target.value);
              }}
              onFocus={() => setLeftVisible(true)}
              size="small"
              InputProps={{
                endAdornment: (
                  <Box sx={{ cursor: "pointer", display: "flex" }}>
                    {leftSelected && (
                      <Box
                        onClick={() => {
                          setLeftText("");
                          setLeftSelected();
                          setData((prev: any) => {
                            return { ...prev, leftOption: "" };
                          });
                        }}
                      >
                        <Box
                          sx={{
                            fontWeight: "700",
                            color: "#707070",
                            paddingRight: "8px",
                            borderRight: "1px solid #707070",
                          }}
                        >
                          x
                        </Box>
                      </Box>
                    )}
                    {isLeftVisible ? (
                      <ArrowDropUpIcon
                        style={{ color: "#707070", cursor: "pointer" }}
                        onClick={() => setLeftVisible(false)}
                      />
                    ) : (
                      <ArrowDropDownIcon
                        style={{ color: "#707070", cursor: "pointer" }}
                        onClick={() => setLeftVisible(true)}
                      />
                    )}
                  </Box>
                ),
              }}
            />
            {isLeftVisible && (
              <Box
                sx={{
                  position: "absolute",
                  width: "calc(100% - 70px)",
                  minHeight: "60px",
                  height: `${filteredLeftOptions?.length * 40}px`,
                  maxHeight: "220px",
                  background: "white",
                  zIndex: 999,
                  top: "44px",
                  overflow: "scroll",
                  cursor: "pointer",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              >
                {filteredLeftOptions?.length ? (
                  filteredLeftOptions.map((item: any) => (
                    <Box
                      key={`left-${item?.id}`}
                      sx={{
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "12px",
                        "&:hover": { background: "#00000040" },
                      }}
                      onClick={() => {
                        setLeftVisible(false);
                        setLeftText(item.name);
                        setLeftSelected(item);
                        setData((prev: any) => {
                          return { ...prev, leftOption: item?.id };
                        });
                      }}
                    >
                      {item.name}
                    </Box>
                  ))
                ) : (
                  <Box
                    sx={{
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "12px",
                    }}
                  >
                    No results found
                  </Box>
                )}
              </Box>
            )}
            <Button
              sx={{
                p: "0px",
                height: "40px",
                "&:disabled": {
                  pointerEvents: "unset",
                  cursor: "not-allowed",
                  background: "#ccc",
                },
              }}
              onClick={addLeftOption}
              disabled={
                !leftText ||
                leftSelected?.name ||
                filteredLeftOptions?.find((item: any) => item.name === leftText)
              }
            >
              <Typography variant="buttonText" fontSize="20px">
                +
              </Typography>
            </Button>
          </Box>
        </Box>

        {/* RIGHT */}
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
          }}
        >
          <Typography variant="paragraph" color="#C2C2C2" mb="8px">
          {t('right-svayp')}
          </Typography>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              display: "flex",
              gap: "5px",
            }}
            ref={outsideRightRef}
          >
            <TextField
              autoComplete="off"
              sx={{ flex: 1 }}
              placeholder={t('select-option')}
              value={rightSelected ? rightSelected.name : rightText}
              onChange={(e) => {
                setRightSelected();
                setData((prev: any) => {
                  return { ...prev, rightOption: "" };
                });
                setRightText(e.target.value);
              }}
              onFocus={() => setRightVisible(true)}
              size="small"
              InputProps={{
                endAdornment: (
                  <Box sx={{ cursor: "pointer", display: "flex" }}>
                    {rightSelected && (
                      <Box
                        onClick={() => {
                          setRightText("");
                          setRightSelected();
                          setData((prev: any) => {
                            return { ...prev, rightOption: "" };
                          });
                        }}
                      >
                        <Box
                          sx={{
                            fontWeight: "700",
                            color: "#707070",
                            paddingRight: "8px",
                            borderRight: "1px solid #707070",
                          }}
                        >
                          x
                        </Box>
                      </Box>
                    )}
                    {isRightVisible ? (
                      <ArrowDropUpIcon
                        style={{ color: "#707070", cursor: "pointer" }}
                        onClick={() => setRightVisible(false)}
                      />
                    ) : (
                      <ArrowDropDownIcon
                        style={{ color: "#707070", cursor: "pointer" }}
                        onClick={() => setRightVisible(true)}
                      />
                    )}
                  </Box>
                ),
              }}
            />
            {isRightVisible && (
              <Box
                sx={{
                  position: "absolute",
                  width: "calc(100% - 70px)",
                  minHeight: "60px",
                  height: `${filteredRightOptions?.length * 40}px`,
                  maxHeight: "220px",
                  background: "white",
                  zIndex: 999,
                  top: "44px",
                  overflow: "scroll",
                  cursor: "pointer",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              >
                {filteredRightOptions?.length ? (
                  filteredRightOptions.map((item: any) => (
                    <Box
                      key={`right-${item?.id}`}
                      sx={{
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "12px",
                        "&:hover": { background: "#00000040" },
                      }}
                      onClick={() => {
                        setRightVisible(false);
                        setRightText(item.name);
                        setRightSelected(item);
                        setData((prev: any) => {
                          return { ...prev, rightOption: item?.id };
                        });
                      }}
                    >
                      {item.name}
                    </Box>
                  ))
                ) : (
                  <Box
                    sx={{
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "12px",
                    }}
                  >
                    No results found
                  </Box>
                )}
              </Box>
            )}
            <Button
              sx={{
                p: "0px",
                height: "40px",
                "&:disabled": {
                  pointerEvents: "unset",
                  cursor: "not-allowed",
                  background: "#ccc",
                },
              }}
              onClick={addRightOption}
              disabled={
                !rightText ||
                rightSelected?.name ||
                filteredRightOptions?.find(
                  (item: any) => item.name === rightText
                )
              }
            >
              <Typography variant="buttonText" fontSize="20px">
                +
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
          marginTop: "12px",
        }}
      >
        {/* TOP */}
        {
          showTopOption &&
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
          }}
        >
          <Typography variant="paragraph" color="#C2C2C2" mb="8px">
          {t('top-svayp')}
          </Typography>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              display: "flex",
              gap: "5px",
            }}
            ref={outsideTopRef}
          >
            <TextField
              autoComplete="off"
              sx={{ flex: 1 }}
              placeholder={t('select-option')}
              value={topSelected ? topSelected.name : topText}
              onChange={(e) => {
                setTopSelected();
                setData((prev: any) => {
                  return { ...prev, topOption: "" };
                });
                setTopText(e.target.value);
              }}
              onFocus={() => setTopVisible(true)}
              size="small"
              InputProps={{
                endAdornment: (
                  <Box sx={{ cursor: "pointer", display: "flex" }}>
                    {topSelected && (
                      <Box
                        onClick={() => {
                          setTopText("");
                          setTopSelected();
                          setData((prev: any) => {
                            return { ...prev, topOption: "" };
                          });
                        }}
                      >
                        <Box
                          sx={{
                            fontWeight: "700",
                            color: "#707070",
                            paddingRight: "8px",
                            borderRight: "1px solid #707070",
                          }}
                        >
                          x
                        </Box>
                      </Box>
                    )}
                    {isTopVisible ? (
                      <ArrowDropUpIcon
                        style={{ color: "#707070", cursor: "pointer" }}
                        onClick={() => setTopVisible(false)}
                      />
                    ) : (
                      <ArrowDropDownIcon
                        style={{ color: "#707070", cursor: "pointer" }}
                        onClick={() => setTopVisible(true)}
                      />
                    )}
                  </Box>
                ),
              }}
            />
            {isTopVisible && (
              <Box
                sx={{
                  position: "absolute",
                  width: "calc(100% - 70px)",
                  minHeight: "60px",
                  height: `${filteredTopOptions?.length * 40}px`,
                  maxHeight: "220px",
                  background: "white",
                  zIndex: 999,
                  top: "44px",
                  overflow: "scroll",
                  cursor: "pointer",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              >
                {filteredTopOptions?.length ? (
                  filteredTopOptions.map((item: any) => (
                    <Box
                      key={`top-${item?.id}`}
                      sx={{
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "12px",
                        "&:hover": { background: "#00000040" },
                      }}
                      onClick={() => {
                        setTopVisible(false);
                        setTopText(item.name);
                        setTopSelected(item);
                        setData((prev: any) => {
                          return { ...prev, topOption: item?.id };
                        });
                      }}
                    >
                      {item.name}
                    </Box>
                  ))
                ) : (
                  <Box
                    sx={{
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "12px",
                    }}
                  >
                    No results found
                  </Box>
                )}
              </Box>
            )}
            <Button
              sx={{
                p: "0px",
                height: "40px",
                "&:disabled": {
                  pointerEvents: "unset",
                  cursor: "not-allowed",
                  background: "#ccc",
                },
              }}
              onClick={addTopOption}
              disabled={
                !topText ||
                topSelected?.name ||
                filteredTopOptions?.find((item: any) => item.name === topText)
              }
            >
              <Typography variant="buttonText" fontSize="20px">
                +
              </Typography>
            </Button>
          </Box>
        </Box>
        }
      </Box>
    </Box>
  );
};

export default CardOptions;
