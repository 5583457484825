enum logType {
  Added = "Added",
  Updated = "Updated",
  Edited = "Edited",
  Deleted = "Deleted",
  Fixed = "Fixed",
  Security = "Security",
  Changed = "Changed",
  Renamed = "Renamed",
}

export interface ChangeLog {
  id: string;
  date: string;
  title: string;
  description: string;
  detail: ChangeLogDetail[];
}

export interface ChangeLogDetail {
  id: string;
  type: logType;
  feautre: string;
}

export const changeLogsData = [
  {
    version: "1.0.6",
    date: "22/03/2024",
    title: "Icons, Sounds, Share Card 🎨",
    description: "Card color picker added, Live Votes Sound added, Layout new icons, Share Card Page added, UI fixes",
    detail: [
      {
        id: "7.1",
        type: logType.Added,
        feature: "Card adding page has been edited and Card color picker added. <a href='/cards/addCard'>Go to page</a>",
      },
      {
        id: "7.2",
        type: logType.Added,
        feature: "Live votes notification added to the dashboard. <a href='/'>Go to page</a>",
      },
      {
        id: "7.3",
        type: logType.Added,
        feature: "Live votes GreenDot added pulse animation.",
      },
      {
        id: "7.4",
        type: logType.Added,
        feature: "Share card page added <a href='/cards/share'>Go to page</a>",
      },
      {
        id: "7.5",
        type: logType.Added,
        feature: "Live votes fetch database data & skeleton added.",
      },
      {
        id: "7.6",
        type: logType.Added,
        feature: "User delete ability added. <a href='/users'>Go to page</a>",
      },
      {
        id: "7.7",
        type: logType.Updated,
        feature: "Layout menu icon changed to a new icon.",
      },
      {
        id: "7.8",
        type: logType.Updated,
        feature: "Homepage cards edited and added animationAnimation was added to the cards on the dashboard, the footer was hidden and converted to Accordion.",
      },
      {
        id: "7.9",
        type: logType.Updated,
        feature: "Users page updated Filter and pagination added. <a href='/users'>Go to page</a>",
      },
    ],
  },
  {
    version: "1.0.5",
    date: "30/01/2024",
    title: "TopVote, Flag, Scrollbar, Error Messages 💄",
    description: "TopVote option added to the card, Dashboard all countries flag added, New scrollbar added country choose list, Login page error messages added",
    detail: [
      {
        id: "6.1",
        type: logType.Added,
        feature: "TopVote option added to the card. <a href='/cards/addCard'>Go to page</a>",
      },
      {
        id: "6.2",
        type: logType.Updated,
        feature: "Card options length validation. 20 characters.",
      },
      {
        id: "6.3",
        type: logType.Added,
        feature: "Dashboard all countries flag added.",
      },
      {
        id: "6.4",
        type: logType.Added,
        feature: "New scrollbar added country choose list.",
      },
      {
        id: "6.5",
        type: logType.Added,
        feature: "Login page error messages added.",
      },
      {
        id: "6.6",
        type: logType.Added,
        feature: "New Language added. French."
      }
    ]
  },
  {
    version: "1.0.4",
    date: "03/11/2023",
    title: "Date Fix, Animations, MIME Formats 🤜🤛",
    description: "Card date fix, websocket animations, AVIF formats",
    detail: [
      {
        id: "5.1",
        type: logType.Added,
        feature: "AVIF format added to images",
      },
      {
        id: "5.2",
        type: logType.Added,
        feature: "Added animation to the live elections section.",
      },
      {
        id: "5.3",
        type: logType.Fixed,
        feature: "Start date and end date timezone format fixed",
      },
      {
        id: "5.4",
        type: logType.Fixed,
        feature: "Delete card after reflesh page fixed",
      },
      {
        id: "5.5",
        type: logType.Fixed,
        feature: "Fixed the issue of 0 count display for cards on the dashboard.",
      },
      {
        id: "5.6",
        type: logType.Edited,
        feature: "Added country images instead of country icons on the countries page.",
      },
      {
        id: "5.7",
        type: logType.Edited,
        feature: "Revised the Analytics page to display card images + date instead of date.",
      },
      {
        id: "5.8",
        type: logType.Edited,
        feature: "Refreshing the page when the card is deleted.",
      },
    ],
  },
  {
    version: "1.0.3",
    date: "14/08/2023",
    title: "Moderator password, and themme update 🎨",
    description: "Moderator set password, and theme update",
    detail: [
      {
        id: "4.1",
        type: logType.Added,
        feature: "Moderator page set password added",
      },
      {
        id: "4.2",
        type: logType.Added,
        feature: "Moderator page error messages added",
      },
      {
        id: "4.3",
        type: logType.Edited,
        feature: "Header shadow changed",
      }
    ],
  },
  {
    version: "1.0.2",
    date: "13/08/2023",
    title: "Bug fix, error messages 🐛",
    description: "Add card options bug fixed, error messages added",
    detail: [
      {
        id: "3.1",
        type: logType.Fixed,
        feature: "Fixed alphabetical ordering error in card options",
      },
      {
        id: "3.2",
        type: logType.Added,
        feature: "Added error messages to card add page",
      },
      {
        id: "3.3",
        type: logType.Added,
        feature: "Added English and Turkish option to card errors",
      },
      {
        id: "3.4",
        type: logType.Added,
        feature: "Map arrangements were made for the countries. on the dashboard",
      },
      {
        id: "3.4",
        type: logType.Edited,
        feature: "Login page English Image text changed",
      },
    ],
  },
  {
    version: "1.0.1",
    date: "13/08/2023",
    title: "Version update ⚡",
    description: "Version system integrated",
    detail: [
      {
        id: "2.1",
        type: logType.Added,
        feature: "Added version next to logo",
      },
      {
        id: "2.2",
        type: logType.Added,
        feature: "Change log page added <a href='/changeLogs'>Go to page</a>",
      },
    ],
  },
  {
    version: "1.0.0",
    date: "12/08/2023",
    title: "Card and Country update 🎉",
    description: "Card view has been changed and country list has been updated",
    detail: [
      {
        id: "1.1",
        type: logType.Edited,
        feature: "All countries added to status bar",
      },
      {
        id: "1.2",
        type: logType.Edited,
        feature: "If there is no card in the country, the loader will render",
      },
      {
        id: "1.3",
        type: logType.Edited,
        feature: "Dashboard responsive edits",
      },
      {
        id: "1.4",
        type: logType.Fixed,
        feature: "Card view percentages down",
      },
    ],
  },
];

export const latestVersion = changeLogsData[0].version;